* {
  font-family: 'proxima-nova', sans-serif ;
  text-rendering: optimizeLegibility;
  box-shadow: none !important;
}

.fa,
.far,
.fas {
  font-family: 'Font Awesome 5 Free' ;
}

.fab {
  font-family: 'Font Awesome 5 Brands' ;
}

body {
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}



.buttonAdd{
  -webkit-box-align: center;
    align-items: center;
    background-color: rgb(45, 47, 55);
    border: 0px;
    border-radius: 16px;
    box-shadow: none;
    color: rgb(255, 114, 13);
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    width: max-content;
    height: 32px;
    line-height: 1;
    letter-spacing: 0.03em;
    -webkit-box-pack: center;
    justify-content: center;
    outline: 0px;
    padding: 0px 16px;
    transition: background-color 0.2s ease 0s;
    opacity: 1
}