.navBar {
  position: fixed;
  top: 0px;
  left: 0;
  transition: top 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  width: 100%;
  height: 60px;
  background-color: #00131F;
  z-index: 20;
  transform: translate3d(0, 0, 0);
}
